import React, { ReactNode } from 'react'
import { Box, Button, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Heading } from '../../../../components/typography'
import { tokens } from '../../../../styles/materialTheme'
import { getUserInfo } from '../../../../store/userInfo/userInfoSlice'
import { Styles } from '../../../../types'

const StyledSection = styled('section')(({ theme }) => ({
  backgroundColor: `${tokens.colors.white}`,
  paddingTop: '2.5rem',
  paddingBottom: '2.5rem',
  paddingLeft: '2.5rem',
  paddingRight: '2.5rem',
  [theme.breakpoints.only('mobile')]: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  [theme.breakpoints.only('tablet')]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}))

const style: Styles = {
  phoneLinkStyle: (theme) => ({
    marginLeft: '0.15rem',
    color: theme.palette.secondary.main,
    fontWeight: '800',
    display: 'block',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginTop: '1.25rem',
  }),

  boxStyles: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '1rem',
    fontFamily: 'Prelo-Book, sans-serif',
    color: theme.palette.secondary.main,
    fontWeight: '800',
    lineHeight: '1.5rem',
  }),
}

interface SupportFormCardProps {
  desc: string
  children: ReactNode
  handleSubmit: () => void
  submitBtnDisabled?: boolean
  testId: string
}

const SupportFormCard = ({
  desc,
  children,
  handleSubmit,
  submitBtnDisabled = false,
  testId,
}: SupportFormCardProps) => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const internalPhone = '877-462-7573'
  const externalPhone = '833-518-3394'
  const supportPhone = userInfo.isEmployee ? internalPhone : externalPhone

  return (
    <form onSubmit={handleSubmit}>
      <Box
        borderColor={tokens.colors.rsmGray.disabled}
        sx={{
          flexWrap: 'wrap',
          borderWidth: '.0625rem',
          borderStyle: 'solid',
        }}>
        <StyledSection data-testid="Sec_Support_FormBox">
          <Heading variant="h1" testId="Hed_Support_SupportFormPage">
            {t('RSMSupportHeading')}
          </Heading>
          <Box
            data-testid={`Lbl_${testId}_Description`}
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1,1fr)',
              gap: '0.5rem',
              paddingBottom: '1.0rem',
              paddingTop: '1.5rem',
              fontSize: '1rem',
              fontFamily: 'Prelo-Book, sans-serif',
            }}>
            {desc}
          </Box>
          <Box
            data-testid={`Lbl_${testId}_CustomerService`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              paddingBottom: '2.5rem',
            }}>
            {t('CustomerService')}
            <Box sx={style.boxStyles}>
              <a
                data-testid="Lnk_Support_CallUsCardPhoneNumber"
                href={`tel:${supportPhone?.replaceAll('-', '')}`}
                className="font-prelo-book mobile:text-[1rem] mobile:leading-[1.5rem] text-[1rem] leading-[1.5rem] block mt-[0.25rem]">
                {supportPhone}
              </a>
            </Box>
          </Box>
          {children}
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 0%',
              justifyContent: 'center',
              paddingTop: '2rem',
            }}>
            <Button
              variant="contained"
              type="submit"
              sx={{ maxHeight: '3.125rem' }}
              color="primary"
              disabled={submitBtnDisabled}
              data-testid={`Btn_${testId}_Submit`}>
              {t('Submit')}
            </Button>
          </Box>
          <Box
            data-testid={`Lbl_${testId}_Description`}
            sx={{
              display: 'flex',
              flex: '1 1 0%',
              justifyContent: 'center',
              paddingTop: '2rem',
              fontSize: '1rem',
              fontFamily: 'Prelo-Book, sans-serif',
            }}>
            {t('SupportPageFormFotterDesc')}
          </Box>
        </StyledSection>
      </Box>
    </form>
  )
}

export default SupportFormCard
