import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Snackbar } from '@mui/material'
import Slide from '@mui/material/Slide'
import { AnalyticEvent } from 'src/analytics/adobe/types'
import { ToastSuccessIcon } from '../../../icons'
import tokens from '../../../../styles/tokens.json'
import { useStyles } from '../Alert.styles'

const analyticEventType: AnalyticEvent = 'SuccessBannerPage_View'
export type CustomSuccessAlertProps = {
  message: string
  messageParams?: any
  header: string
  open: boolean
  close: () => void
  testId: string
  overrideToastMsgTimeLimit?: number | null
  dataAnalyticsId?: string
}

const CustomSuccessAlert = ({
  header,
  message,
  messageParams = null,
  open,
  close,
  testId,
  overrideToastMsgTimeLimit = null,
  dataAnalyticsId = '',
}: CustomSuccessAlertProps) => {
  const { t } = useTranslation()
  const toastMsgTimelimit = 8000
  const toastMsgSlideEnter = 1000
  const toastMsgSlideExit = 1000

  const classes = useStyles()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transitionDuration={{
        enter: toastMsgSlideEnter,
        exit: toastMsgSlideExit,
      }}
      TransitionComponent={Slide}
      autoHideDuration={
        overrideToastMsgTimeLimit !== null
          ? overrideToastMsgTimeLimit
          : toastMsgTimelimit
      }
      open={open}
      onClose={() => close()}
      sx={classes.successSnackbar}
      data-testid={`${testId}_Snackbar`}
      data-analytic-event={analyticEventType}
      data-analytic-id={dataAnalyticsId === '' ? testId : dataAnalyticsId}>
      <Alert
        aria-atomic="true"
        aria-live="assertive"
        severity="success"
        icon={<ToastSuccessIcon color={tokens.colors.rsmGray.copy} />}
        sx={classes.successAlert}>
        <div className="m-[1.5625rem]">
          <div
            style={{ marginBottom: '0.3125rem', fontWeight: 'bold' }}
            data-testid={`${testId}_Header`}
            data-analytic-event={analyticEventType}
            data-analytic-id={
              dataAnalyticsId === '' ? testId : dataAnalyticsId
            }>
            {t(header)}
          </div>
          <div
            data-testid={`${testId}_Message`}
            data-analytic-event={analyticEventType}
            data-analytic-id={
              dataAnalyticsId === '' ? testId : dataAnalyticsId
            }>
            {`${t(message, messageParams)}`}
          </div>
        </div>
      </Alert>
    </Snackbar>
  )
}

export default CustomSuccessAlert
