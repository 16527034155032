/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js'
import { AuditActionEnum } from '@rsmus/ecp-userservice'
import {
  Anonymous,
  Authenticated,
  getAccessToken,
  OidcIdentity,
} from '@rsmus/react-auth'
import { Eula } from '@rsmus/rsm-eula'
import React, { lazy, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Styles } from './types'
import Unavailable from './pages/Unavailable'
import Layout from './components/layouts/Layout'
import ProtectedRoute from './components/ProtectedRoute'
import ProtectedRouteError from './components/ProtectedRouteError'
import SessionManager from './components/SessionManager'
import VersionService from './components/VersionService'
import ContactUsBaseLayout from './pages/Support/ContactUsBaseLayout'
import {
  APP_REACT_AUTH_AUTHORITY,
  APP_REACT_AUTH_CLIENTID,
  IDM_EULA_SERVICE_URL,
} from './envVariables'
import Docs from './pages/Docs'
import Engagement from './pages/Engagement'
import EngagementList from './pages/EngagementList'
import Faq from './pages/Faq'
import FeaturesPage from './pages/FeaturesPage'
import Home from './pages/Home'
import Insight from './pages/Insight'
import Insights from './pages/Insights'
import NoMatch from './pages/NoMatch'
import Notifications from './pages/Notifications'
import Profile from './pages/Profile'
import ProjectsPage from './pages/ProjectsPage'
import Support from './pages/Support'
import { ScrollToTop } from './rsmCoreComponents'
import Refocus from './rsmCoreComponents/components/ReFocus'
import { rcPlugin } from './rsmCoreComponents/hooks/appInsights'
import LazyLoader from './rsmCoreComponents/LazyLoader'
import { isFeatureFlagEnabled } from './rsmCoreComponents/utils/featureFlagUtils'
import {
  AuditLoginTopic,
  subscribeToAuditTopics,
} from './utils/helpers/Audit.service'
import { publish } from './utils/helpers/PubSub.service'
import './index.css'
import {
  AccountManagement,
  DashboardLayout,
  InvoicesLayout,
  PaymentType,
} from './components/Invoicing'
import PaymentHistoryLayout from './components/Invoicing/Invoices/PaymentHistoryLayout'
import InvoiceProtectedLayout from './pages/Invoicing'
import InvoicePaymentSuccess from './components/Invoicing/Invoices/InvoicePaymentSuccess'
import InvoicesPaymentTable from './components/Invoicing/Invoices/InvoicesPaymentTable'
import DeepLink from './components/DeepLink/DeepLink'
import { setRedirectUrl } from './store/deepLink/deepLinkSlice'
import ReportAnIssue from './pages/ReportAnIssue'
import {
  CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
  CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
  INVOICING_FEATURE_AUTOPAY,
} from './utils/constants/constants'
import TrackingManager from './components/TrackingManager/TrackingManager'
import { Interceptor } from './api/Interceptor/Interceptor'
import SupportPage from './pages/Support/SupportPage'

const styles: Styles = {
  // Style for Okta app authorization failure message container.
  unavailableContainer: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    height: '33.5rem',
  }),
}

const autoLogin = true
const autoRedirect = true
// Force the rsm logo to display in the Unavailable component
// when user authorization fails.
const showRsmLogoOnAuthFailure = true
const redirectURI = 'https://rsmus.com'

// Query string parameter and value constants.
// Okta produces these when the user is authenticated
// but not authorized for the application.
const errorDescriptionQueryStringParm = 'error_description'
const unauthorizedForAppErrorMessage =
  'User+is+not+assigned+to+the+client+application.'
const userCreationDisabledErrorMessage = 'User+creation+was+disabled.'

const DocumentRequestsPage = LazyLoader(
  lazy(() => import('./pages/DocumentRequestsPage')),
)

const App = () => {
  const dispatch = useDispatch()
  const getToken = async () => {
    const result = await getAccessToken()
    return result || ''
  }

  useEffect(() => {
    subscribeToAuditTopics()
  }, [])

  const handleRedirectCallback = (url: string) => {
    publish(AuditLoginTopic, AuditActionEnum.LoginSucceeded)

    const cleanUrl = new URL(url.replace(/\/$/g, ''))
    cleanUrl.searchParams.delete('code')
    cleanUrl.searchParams.delete('access_token')

    if (cleanUrl.toString() !== window.location.origin) {
      dispatch(setRedirectUrl(cleanUrl.toString()))
    }
  }

  const onError = () => <ProtectedRouteError />

  // Get the query string value based provided a query string parameter name.
  const getQueryStringValue = (parmName: string): string | null => {
    const query = window.location.search.substring(1)
    const splitParms = query.split('&')
    for (let i = 0; i < splitParms.length; i += 1) {
      const pair = splitParms[i].split('=')
      if (pair[0] === parmName) {
        return pair[1]
      }
    }
    return null
  }

  // If the environment variables failed to load, display the unavailable page.
  if (!APP_REACT_AUTH_AUTHORITY) {
    return (
      <BrowserRouter>
        <Box sx={styles.unavailableContainer}>
          <Unavailable />
        </Box>
      </BrowserRouter>
    )
  }

  const showTryAgain = true

  return (
    <OidcIdentity
      redirect_uri={window.location.origin}
      authority={APP_REACT_AUTH_AUTHORITY}
      client_id={APP_REACT_AUTH_CLIENTID}
      autoLogin={autoLogin}
      redirectAfterLogout={autoRedirect}
      postLogoutRedirectUri={redirectURI}
      onDeepLinkRedirectCallback={handleRedirectCallback}
      silentRequestTimeoutInSeconds={60}>
      <Anonymous>
        <Interceptor>
          <BrowserRouter>
            {getQueryStringValue(errorDescriptionQueryStringParm) ===
              unauthorizedForAppErrorMessage && (
              <Box sx={styles.unavailableContainer}>
                <Unavailable
                  returnUrl="#"
                  headerTextKey="Authorization.NotAuthorizedForApplication.NotAuthorizedHeader"
                  subHeaderTextheaderTextKey="Authorization.NotAuthorizedForApplication.NotAuthorizedReason"
                  subHeader2ndLineTextheaderTextKey="Authorization.NotAuthorizedForApplication.NotAuthorizedPrimarySuggestion"
                  subHeader3rdLineTextheaderTextKey="Authorization.NotAuthorizedForApplication.NotAuthorizedSecondarySuggestion"
                  showTryAgain={false}
                  showRsmLogo={showRsmLogoOnAuthFailure}
                />
              </Box>
            )}
            {getQueryStringValue(errorDescriptionQueryStringParm) ===
              userCreationDisabledErrorMessage && (
              <Box sx={styles.unavailableContainer}>
                <Unavailable
                  returnUrl={window.location.origin}
                  headerTextKey="Authorization.NotAuthorizedForApplication.NotAuthorizedHeader"
                  subHeaderTextheaderTextKey="Authorization.NotAuthorizedForApplication.NotAuthorizedReason"
                  subHeader2ndLineTextheaderTextKey="Authorization.NotAuthorizedForApplication.NotAuthorizedPrimarySuggestion"
                  subHeader3rdLineTextheaderTextKey="Authorization.NotAuthorizedForApplication.NotAuthorizedSecondarySuggestion"
                  showTryAgain={showTryAgain}
                  showRsmLogo={showRsmLogoOnAuthFailure}
                />
              </Box>
            )}
            {getQueryStringValue(errorDescriptionQueryStringParm) !== null &&
              getQueryStringValue(errorDescriptionQueryStringParm) !==
                userCreationDisabledErrorMessage &&
              getQueryStringValue(errorDescriptionQueryStringParm) !==
                unauthorizedForAppErrorMessage && (
                <Box sx={styles.unavailableContainer}>
                  <Unavailable
                    returnUrl="#"
                    showTryAgain={false}
                    showRsmLogo={showRsmLogoOnAuthFailure}
                  />
                </Box>
              )}
          </BrowserRouter>
        </Interceptor>
      </Anonymous>
      <Authenticated>
        <Interceptor>
          <>
            <VersionService />
            <BrowserRouter>
              <DeepLink />
              <AppInsightsErrorBoundary
                onError={() => onError()}
                appInsights={rcPlugin}>
                <Eula host={IDM_EULA_SERVICE_URL} tokenFactory={getToken}>
                  <SessionManager />
                  <TrackingManager />
                  <Refocus>
                    <AppInsightsContext.Provider value={rcPlugin}>
                      <ScrollToTop />
                      <Routes>
                        <Route element={<Layout />}>
                          <Route index element={<Home />} />
                          <Route path="/" element={<Home />} />
                          <Route
                            path="/engagements"
                            element={
                              <ProtectedRoute
                                targetedFeature="Targeting_Engagement"
                                matchType="any">
                                <EngagementList />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/engagements/:engagementId"
                            element={
                              <ProtectedRoute
                                targetedFeature="Targeting_Engagement"
                                matchType="any">
                                <Engagement />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/engagements/:engagementId/requests/*"
                            element={
                              <ProtectedRoute
                                feature="DocumentRequest"
                                targetedFeature="Targeting_Engagement"
                                matchType="any">
                                <DocumentRequestsPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/engagements/:engagementId/docs"
                            element={
                              <ProtectedRoute
                                feature="DocumentRequest"
                                targetedFeature="Targeting_Engagement"
                                requireEmployee
                                matchType="any">
                                <Docs />
                              </ProtectedRoute>
                            }
                          />
                          <Route path="/insights" element={<Insights />} />
                          <Route
                            path="/insights/:insightId"
                            element={<Insight />}
                          />
                          <Route
                            path="/invoicing"
                            element={
                              <ProtectedRoute
                                feature="Invoicing"
                                cemFeature={[
                                  CEM_FEATURE_PAYMENT_PAY_INVOICES,
                                  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                                  CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
                                ]}
                                matchType="any">
                                <InvoiceProtectedLayout />
                              </ProtectedRoute>
                            }>
                            <Route
                              index
                              element={
                                <ProtectedRoute
                                  cemFeature={CEM_FEATURE_PAYMENT_CLIENT_ADMIN}
                                  cemFeatureFallback={
                                    <Navigate
                                      to="/invoicing/invoices"
                                      replace
                                    />
                                  }
                                  matchType="any">
                                  <Navigate to="/invoicing/dashboard" replace />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="dashboard"
                              element={
                                <ProtectedRoute
                                  cemFeature={CEM_FEATURE_PAYMENT_CLIENT_ADMIN}
                                  matchType="any">
                                  <DashboardLayout />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="/invoicing/dashboard/:clientId"
                              element={
                                <ProtectedRoute
                                  cemFeature={CEM_FEATURE_PAYMENT_CLIENT_ADMIN}
                                  matchType="any">
                                  <DashboardLayout />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path="/invoicing/invoices/:clientId"
                              element={
                                <ProtectedRoute
                                  cemFeature={[
                                    CEM_FEATURE_PAYMENT_PAY_INVOICES,
                                    CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                                    CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
                                  ]}
                                  matchType="any">
                                  <InvoicesLayout />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="invoices"
                              element={
                                <ProtectedRoute
                                  cemFeature={[
                                    CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                                    CEM_FEATURE_PAYMENT_PAY_INVOICES,
                                  ]}
                                  matchType="any">
                                  <InvoicesLayout />
                                </ProtectedRoute>
                              }>
                              <Route
                                path="payment-type"
                                element={<PaymentType />}
                              />
                              <Route
                                path="pay-on-account"
                                element={<PaymentType isPayOnAccount />}
                              />
                              {/* add protected route for autopay feature flag */}
                              <Route
                                path="autopay"
                                element={
                                  <ProtectedRoute
                                    feature={INVOICING_FEATURE_AUTOPAY}
                                    matchType="all">
                                    <PaymentType isAutopay />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="payment-success"
                                element={<InvoicePaymentSuccess />}
                              />
                              <Route
                                path="pay-invoices"
                                element={<InvoicesPaymentTable />}
                              />
                            </Route>
                            <Route
                              path="payments"
                              element={
                                <ProtectedRoute
                                  cemFeature={[
                                    CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                                    CEM_FEATURE_PAYMENT_PAY_INVOICES,
                                  ]}
                                  matchType="any">
                                  <PaymentHistoryLayout />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="account-management"
                              element={
                                <ProtectedRoute
                                  cemFeature={[
                                    CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                                    CEM_FEATURE_PAYMENT_PAY_INVOICES,
                                  ]}
                                  matchType="any">
                                  <ProtectedRoute
                                    cemFeature={
                                      CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT
                                    }
                                    matchType="any">
                                    <AccountManagement />
                                  </ProtectedRoute>
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="/invoicing/account-management/:clientId"
                              element={
                                <ProtectedRoute
                                  cemFeature={[
                                    CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                                    CEM_FEATURE_PAYMENT_PAY_INVOICES,
                                  ]}
                                  matchType="any">
                                  <ProtectedRoute
                                    cemFeature={
                                      CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT
                                    }
                                    matchType="any">
                                    <AccountManagement />
                                  </ProtectedRoute>
                                </ProtectedRoute>
                              }
                            />
                          </Route>
                          <Route
                            path="/projects/*"
                            element={<ProjectsPage />}
                          />
                          <Route
                            path="/notifications"
                            element={<Notifications />}
                          />
                          {isFeatureFlagEnabled('UserProfile') && (
                            <Route path="/profile" element={<Profile />} />
                          )}
                          <Route path="/support" element={<Support />} />
                          <Route
                            path="/reportanissue"
                            element={<ReportAnIssue />}
                          />
                          <Route path="/faq" element={<Faq />} />
                          <Route
                            path="/debug/features"
                            element={<FeaturesPage />}
                          />
                          <Route path="*" element={<NoMatch />} />

                          <Route
                            path="/contact-us"
                            element={<ContactUsBaseLayout />}>
                            <Route
                              index
                              element={
                                <Navigate
                                  to="/contact-us/support-page"
                                  replace
                                />
                              }
                            />
                            <Route
                              path="support-page"
                              element={<SupportPage />}
                            />
                            <Route path="faq" element={<Faq />} />
                            <Route path="feedback" element={<NoMatch />} />
                          </Route>
                        </Route>
                      </Routes>
                    </AppInsightsContext.Provider>
                  </Refocus>
                </Eula>
              </AppInsightsErrorBoundary>
            </BrowserRouter>
          </>
        </Interceptor>
      </Authenticated>
    </OidcIdentity>
  )
}

export default App
