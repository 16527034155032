/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Box, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MainMenu from 'src/components/layouts/MainMenu'
import processAdobePageView from '../../analytics/adobe/pageView'
import { UserType } from '../../analytics/adobe/types'
import SupportPageForm from '../../components/domain/Support/SupportForm/SupportPageForm'
import MainContent from '../../components/layouts/MainContent'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'

import { Styles } from '../../types'

const pageTitle = 'Support Page'

const styles: Styles = {
  container: (theme) => ({
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
  }),
}

const StyledGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'fit-content',
  marginTop: '2rem',
  gap: '1.25rem',
  [theme.breakpoints.only('tablet')]: {
    gridTemplateColumns: 'repeat(1,1fr)',
  },
  [theme.breakpoints.only('desktop')]: {
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '1.75rem',
  },
}))

const StyledFormGrid = styled('div')({
  gridColumn: 'span 3 / span 3',
})

const SupportPage = () => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const userType: UserType = userInfo.isEmployee ? 'Internal' : 'External'
  useAITracking(t('ECPSupportTitle'), window.location.href)

  useEffect(() => {
    document.title = t('ECPSupportTitle')
    processAdobePageView(pageTitle, userType)
  }, [])

  return (
    <>
      <MainMenu currentItem="contact-us" />
      <MainContent>
        <Box sx={styles.container}>
          <StyledGrid>
            <StyledFormGrid>
              <SupportPageForm />
            </StyledFormGrid>
          </StyledGrid>
        </Box>
      </MainContent>
    </>
  )
}

export default SupportPage
